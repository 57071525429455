import React from "react"

import { navigate } from "gatsby"
import Helmet from "react-helmet"
import favIcon from "../../../assets/muni_favicon.ico"

const BlogComponent = ({
  imgUrl,
  authorProfilePic,
  date,
  title,
  authorName,
  id,
}) => {
  return (
    <div
      role="none"
      className="blog_component"
      onClick={() => {
        navigate(`/company/blog/${id}`)
        window.scrollTo(0, 0)
      }}
      onKeyUp={() => {
        navigate(`/company/blog/${id}`)
        window.scrollTo(0, 0)
      }}
    >
      <Helmet>
        <title>Muni</title>
        <meta name="icon" href={favIcon} />
        <meta
          name="description"
          content="Rydoo Alternative. Muni helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        <meta name="keywords" content="expense management, concur, scan receipts, rydoo, per diem, expenses, expense tracker" />
        <meta property="og:title" content="Muni" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Rydoo Alternative. Muni helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        {/* <meta property="og:image" content="" /> */}
        <meta property="og:locale" content="en_UK" />
        <meta property="og:url" content="https://usemuni.com" />
        <link rel="canonical" href="https://usemuni.com" />
        <link rel="icon" href={favIcon} />
      </Helmet>
      <div className="image_container">
        <img src={imgUrl} alt="Blog container" />
      </div>
      <div className="blog_data_container">
        <span className="date">{date}</span>
        <span className="title">{title}</span>
        <div className="author_details_container">
          <div className="avatar">
            <img src={authorProfilePic} alt="Author's profile pic" />
          </div>
          <span className="author_name">{authorName}</span>
        </div>
      </div>
    </div>
  )
}

export default BlogComponent
